const namespaced = true
const config = require('@/config');

const state = {}

const actions = {
    async getAllExhibitors({dispatch}) {
        return await dispatch('database/getItems', {table: 'exhibitors'}, {root: true})
    },
    async getExhibitor({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'exhibitors', id: id}, {root: true})
    },

    async getExhibitorsById({dispatch}, ids) {
        let exhibitors = await dispatch('database/getItems', {table: 'exhibitors'}, {root: true});
        let exhibitorsToReturn = [];

        exhibitorsToReturn = exhibitors.filter(exhib => ids.indexOf(exhib.id) != -1);
        return exhibitorsToReturn
    },
    async getList({dispatch}) {

        let exhibitions = [];
        let data = await dispatch('database/getMultiple', ['exhibitors', 'locations', 'exhibitor_location_links'], {root: true});

        if (!config.is_app) {
            for (let i = 0; i < data['exhibitors'].length; i++) {
                let exhibitor = data['exhibitors'][i];
                if (exhibitor.is_exhibitor) {
                    exhibitor.locationList = [];
                    for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                        let location_link = data['exhibitor_location_links'][j];
                        if (location_link.exhibitor_id == exhibitor.id) {
                            for (let k = 0; k < data['locations'].length; k++) {
                                let location = data['locations'][k];
                                if (location_link.location_id == location.id) {
                                    exhibitor.locationList.push(location);
                                }
                            }
                        }
                    }
                    exhibitions.push(exhibitor)
                }
            }
        } else {
            for (let i = 0; i < data['exhibitors'].length; i++) {
                let exhibitor = data['exhibitors'][i];
                if (exhibitor.is_exhibitor) {
                    exhibitor.locationList = [];
                    for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                        let location_link = data['exhibitor_location_links'][j];
                        if (location_link.exhibitor_id == exhibitor.id) {
                            for (let k = 0; k < data['locations'].length; k++) {
                                let location = data['locations'][k];
                                if (location_link.location_id == location.id) {
                                    exhibitor.locationList.push(location);
                                }
                            }
                        }
                    }
                    exhibitions.push(exhibitor)
                }
            }
        }

        return exhibitions;
    },
    async getFavoriteExhibitors({dispatch}) {

        let exhibitions = [];
        let data = await dispatch('database/getMultiple', ['exhibitors', 'locations', 'exhibitor_location_links'], {root: true});

        for (let i = 0; i < data['exhibitors'].length; i++) {
            let exhibitor = data['exhibitors'][i];
            if (exhibitor.is_exhibitor && exhibitor.is_active) {
                exhibitor.locationList = [];
                for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                    let location_link = data['exhibitor_location_links'][j];
                    if (location_link.exhibitor_id == exhibitor.id) {
                        for (let k = 0; k < data['locations'].length; k++) {
                            let location = data['locations'][k];
                            if (location_link.location_id == location.id) {
                                exhibitor.locationList.push(location);
                            }
                        }
                    }
                }
                exhibitions.push(exhibitor)
            }
        }
        return exhibitions;
    },

    async getSponsorList({dispatch}) {
        let data = await dispatch('database/getMultiple', ['sponsorlevels', 'exhibitor_sponsorlevel_links', 'exhibitors', 'locations', 'exhibitor_location_links'], {root: true});


        let list = [];
        let levels = data['sponsorlevels'].sort((a, b) => a.order - b.order);
        let exhibitorLevels = data['exhibitor_sponsorlevel_links'].sort((a, b) => a.order - b.order);
        for (let i = 0; i < levels.length; i++) {
            let sponsorlevel = levels[i];
            if (sponsorlevel.id != '9e9dbd30-aab8-4cd3-b176-54d9a5eb542b') {
                for (let j = 0; j < exhibitorLevels.length; j++) {

                    if (!config.is_app) {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {


                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }

                    } else {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {

                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }
                    }

                }
            }

        }
        return list;
    },

    async getSponsorListForLevel({dispatch}, level) {
        let data = await dispatch('database/getMultiple', ['sponsorlevels', 'exhibitor_sponsorlevel_links', 'exhibitors', 'locations', 'exhibitor_location_links'], {root: true});


        let list = [];
        let levels = data['sponsorlevels'].sort((a, b) => a.order - b.order);
        let exhibitorLevels = data['exhibitor_sponsorlevel_links'].sort((a, b) => a.order - b.order);
        for (let i = 0; i < levels.length; i++) {
            let sponsorlevel = levels[i];
            if (sponsorlevel.id == level) {
                for (let j = 0; j < exhibitorLevels.length; j++) {

                    if (!config.is_app) {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {


                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }

                    } else {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {

                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }
                    }

                }
            }

        }
        return list;
    },

    async getMedias({dispatch}, params) {
        let id = params.id;
        let type = params.type;
        let medias = [];
        let data = await dispatch('database/getMultiple', ['medias', 'exhibitor_media_links'], {root: true})
        for (let i = 0; i < data['exhibitor_media_links'].length; i++) {
            let media_link = data['exhibitor_media_links'][i];
            if (media_link.exhibitor_id == id) {
                for (let j = 0; j < data['medias'].length; j++) {
                    let media = data['medias'][j];
                    if (media_link.media_id == media.id && media.type == type) {
                        medias.push(media);
                    }
                }
            }
        }
        return (medias.sort((a, b) => a.order - b.order));
    },


    async getLocations({dispatch}, id) {
        let locations = [];
        let data = await dispatch('database/getMultiple', ['locations', 'exhibitor_location_links'], {root: true})
        for (let i = 0; i < data['exhibitor_location_links'].length; i++) {
            let location_link = data['exhibitor_location_links'][i];
            if (location_link.exhibitor_id == id) {
                for (let j = 0; j < data['locations'].length; j++) {
                    let location = data['locations'][j];
                    if (location_link.location_id == location.id) {
                        locations.push(location)
                    }
                }
            }
        }
        return (locations);
    },
    async getListForLocation({dispatch}, id) {
        let exhibitors = [];
        let data = await dispatch('database/getMultiple', ['exhibitors', 'exhibitor_location_links', 'locations'], {root: true})
        for (let i = 0; i < data['exhibitor_location_links'].length; i++) {
            let location_link = data['exhibitor_location_links'][i];
            if (location_link.location_id == id) {
                for (let j = 0; j < data['exhibitors'].length; j++) {
                    let exhibitor = data['exhibitors'][j];


                    if (location_link.exhibitor_id == exhibitor.id) {
                        exhibitor.locationList = [];
                        for (let k = 0; k < data['locations'].length; k++) {
                            let location = data['locations'][k];
                            if (location.id == id) {
                                exhibitor.locationList.push(location);
                            }
                        }
                        exhibitors.push(exhibitor)
                    }
                }
            }
        }
        return (exhibitors);
    },

    async getDetails({dispatch}, exhibitor) {
        let details = {};
        details.locations = await dispatch('getLocations', exhibitor);
        details.files = await dispatch('getMedias', {id: exhibitor, type: 'file'});
        details.links = await dispatch('getMedias', {id: exhibitor, type: 'link'});
        details.videos = await dispatch('getMedias', {id: exhibitor, type: 'video'});
        details.virtualbooth = await dispatch('getMedias', {id: exhibitor, type: 'virtualbooth'});

        return details;
    },


}
const mutations = {}
const getters = {}

export const exhibitors = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
