const namespaced = true
import axios from 'axios';

const state = {}

const actions = {
    async getAllPolls({dispatch}) {
        return await dispatch('database/getItems', {table: 'polls'}, {root: true})
    },
    async getPollById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'polls', id: id}, {root: true})
    },

    async getAllQuestions({dispatch}) {
        return await dispatch('database/getItems', {table: 'questions'}, {root: true})
    },
    async getQuestionById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'questions', id: id}, {root: true})
    },
    async getPollForState({dispatch}, location) {
        let polls = await dispatch('database/getItems', {table: 'polls'}, {root: true});
        let pollsToReturn = [];
        polls.forEach(poll => {
            if (location.hash.indexOf(poll.state) === 0) {
                let part = location.hash.split('/');
                let ref_id = part.pop();
                let ref_type = part.pop();
                poll.ref_type = ref_type;
                poll.ref_id = ref_id;
                pollsToReturn.push(poll);
            }

        });
        return pollsToReturn
    },

    //save
    async saveAnswers({rootState, dispatch}, payload) {
        let a = payload.question;
        let b = payload.answer;
        let c = payload.params;

        let votes = JSON.parse(localStorage.getItem(a.congressId + '_votes')) || {};

        votes[c.id + '_' + c.ref_id] = votes[c.id + '_' + c.ref_id] || {};
        votes[c.id + '_' + c.ref_id][a.id] = votes[c.id + '_' + c.ref_id][a.id] || '';

        if (!votes[c.id + '_' + c.ref_id][a.id]) {
            votes[c.id + '_' + c.ref_id][a.id] = b.toString();
            localStorage.setItem(a.congressId + '_votes', JSON.stringify(votes));

            await axios.post('https://polls-core.documedias.com/' + a.congressId + '/analytics', {
                question: a,
                answer: b,
                params: c,
            })

            let trackData = {
                user: rootState.auth.user,
                item: {
                    poll_id: c.id,
                    ref_type: c.ref_type,
                    ref_id: c.ref_id,
                    question: a,
                    answer: b
                },
                event: 'answer',
                type: 'evaluation',
                itemId: a.id,
            }
            await dispatch('cmetracking/trackEvent', trackData, {root: true})
        }
    },

}
const mutations = {}
const getters = {}

export const polls = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
