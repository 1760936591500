const namespaced = true

const state = {
    pushmessages: [
        /*{time:'2021-11-18T10:00:00',
            message: {title:'Test Message 1'},redirect:'123',"linkText":'1234',
            content:' Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically'},
        {time:'2021-11-18T12:00:00',
            message: {title:'Test Message 2'},redirect:'123',linkText:'1234',
            content:' Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically'},
        {time:'2021-11-18T13:00:00',
            message: {title:'Test Message 3'},redirect:'123',linkText:'1234',
            content:' Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically\n        Heads up, toasts will stack automatically'}
    */],

}

const actions = {}
const mutations = {

    addMessage(state, msg) {
        // mutate state
        state.pushmessages.push(msg)
        /*try {
            setTimeout(() => {
               state.pushmessages.splice(state.pushmessages.length - 1, 1);
            }, 20000)
        } catch (err) {
            console.log(err)
        }*/

    },
    markAsRead(state, index) {
        // mutate state
        console.log(index)
        state.pushmessages.splice(index, 1);
    }
}
const getters = {}

export const pushmessages = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
