const namespaced = true

const state = {}

const actions = {
    async getAllLinks({dispatch}) {
        return await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
    },
    async getLinkById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'menulinks', id: id}, {root: true})
    },

    async getParents({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0) {
                try {
                    menulink._title = JSON.parse(menulink.title);
                } catch (err) {
                    menulink._title = menulink.title;
                }
                menulink.is_external = await dispatch('isExternalLink', menulink.target)
                menulink.redirect = await dispatch('checkRedirect', menulink);
                menulink.sublinks = await dispatch('getChildItems', menulink.id)
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },
    async getTopItems({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0 && menulink.place_top) {

                try {
                    menulink._title = JSON.parse(menulink.title);
                } catch (err) {
                    menulink._title = menulink.title;
                }
                menulink.is_external = await dispatch('isExternalLink', menulink.target)
                menulink.redirect = await dispatch('checkRedirect', menulink);
                menulink.sublinks = await dispatch('getChildItems', menulink.id)
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },
    async getDashboardItems({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0) {
                try {
                    menulink._title = JSON.parse(menulink.title);
                } catch (err) {
                    menulink._title = menulink.title;
                }
                menulink.is_external = await dispatch('isExternalLink', menulink.target)
                menulink.redirect = await dispatch('checkRedirect', menulink);
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },
    async getMenuBarItems({dispatch}) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == null && menulink.title.en.length > 0 && menulink.show_in_menubar) {
                try {
                    menulink._title = JSON.parse(menulink.title);
                } catch (err) {
                    menulink._title = menulink.title;
                }
                menulink.is_external = await dispatch('isExternalLink', menulink.target)
                menulink.redirect = await dispatch('checkRedirect', menulink);
                menulinks.push(menulink);
            }
        }
        return menulinks;
    },

    async getChildItems({dispatch}, parent) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == parent && menulink.title.en.length > 0) {
                try {
                    menulink._title = JSON.parse(menulink.title);
                } catch (err) {
                    menulink._title = menulink.title;
                }
                menulink.is_external = await dispatch('isExternalLink', menulink.target)
                menulink.redirect = await dispatch('checkRedirect', menulink);
                menulinks.push(menulink);
            }
        }
        return menulinks.sort((a,b)=>{return a.order-b.order});
    },

    async checkRedirect({state}, link) {
        let redirect = '';
        if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
            redirect = '/app/dashboard/' + link.id;
        } else {
            if (link.is_external) {
                redirect = link.target
            } else {
                redirect = link.target.split('#')[1]
            }
        }
        return redirect;
    },

    async isExternalLink({state},link) {
        return new RegExp("^(http|https)://").test(link) || new RegExp("^(mailto):").test(link)
    },
    async getChildren({dispatch}, parent) {
        let _menulinks = await dispatch('database/getItems', {table: 'menulinks'}, {root: true})
        let menulinks = [];
        for (let i = 0; i < _menulinks.length; i++) {
            let menulink = _menulinks[i];
            if (menulink.parent_id == parent && menulink.title.en.length > 0) {
                menulinks.push(menulink);
            }
        }
        return menulinks;
    }
}
const mutations = {}
const getters = {}

export const menulinks = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
