import { createStore } from "vuex";
import {sync} from './modules/sync'
import {database} from './modules/database';
import {currentConference} from './modules/currentConference';
import {personalDB} from './modules/personalDatabase';
import {files} from './modules/files';
import {splashscreen} from './modules/splash';
import {menulinks} from './modules/models/menulink';
import {sessions} from './modules/models/session';
import {persons} from './modules/models/person';
import {exhibitors} from './modules/models/exhibitor';
import {appointment} from './modules/models/appointment';
import {subpages} from './modules/models/subpage';
import {settings} from './modules/models/settings';
import {sessiontypes} from './modules/models/sessiontype';
import {presentations} from './modules/models/presentation';
import {abstracts} from './modules/models/abstracts';
import {maps} from './modules/models/maps';
import {chat} from '@/components/chat/chat';
import {news} from './modules/models/news';
import {video} from './modules/video'
import {conference} from './modules/models/conference';
import {consultant} from './modules/models/consultant';
import {banners} from './modules/models/banner';
import {helper} from './helper';
import {pushmessages} from './pushmessages';
import {wordings} from './wordings';
import {cmetracking} from './cmetracking';
import {auth} from './auth';
import {googleanalytics} from "@/store/googleanalytics";
import {polls} from './modules/models/polls';
export const store = createStore({
  state:{
      count:1
  },

  // ACTIONS (asynchronous)
  actions: {
  },

  // MUTATIONS ( set the state )
  mutations: {
  },
  modules :{
      banners,
      pushmessages,
      currentConference,
      wordings,
      cmetracking,
      helper,
      sync,
      database,
      personalDB,
      files,
      splashscreen,
      menulinks,
      sessions,
      persons,
      exhibitors,
      subpages,
      settings,
      sessiontypes,
      presentations,
      abstracts,
      maps,
      chat,
      news,
      appointment,
      video,
      conference,
      auth,
      consultant,
      googleanalytics,
      polls,
  }
});
