const namespaced = true

const state = {

  currentLanguage:'de',
  wordings: {
    'de':{
      'FOUND_IN':'Gefunden in:',
      'FAVS' :'Favoriten',
      'NOTES' :'Notizen',
      'MAIL_EXPORT' :'In Mail exportieren',
      'LOGIN':'EINLOGGEN',
      'IMPRINT': 'Impressum',
      'ABOUT':'Über',
      'PRIVACY': 'Datenschutz',
      'SUPPORT': 'Support',
      'SEARCH' : 'Suche',
      'ADD_FAVORITE' : 'Favorit',
      'RM_FAVORITE' : 'Favorit',
      'SAVE_CALENDAR' : 'Im Kalender speichern',
      'CHAIR_HEADER' : 'Vorsitz',
      'SPEAKER_HEADER' : 'Referierende',
      'PRESENTATION_HEADER' : 'Präsentationen',
      'PERSON_SESSION_HEADER': 'Sitzungen dieser Person',
      'EVALUATE': 'Bewerten',
      'SESSION_HEADER': 'Sitzung ',
      'SESSION_HEADER_ALSO': 'Auch hier präsentiert',
      'NOW_HINT': 'Es laufen zur Zeit keine Sitzungen',
      'SECTION_SESSION': 'Sitzungen',
      'SECTION_PRESENTATION': 'Präsentationen',
      'SECTION_PERSON': 'Personen',
      'SECTION_ABSTRACTS': 'Abstracts',
      'SECTION_INDUSTRY': 'Aussteller',
      'NEWS_HINT':'Zur Zeit gibt es keine Nachrichten',
      'COMMENTS': 'Kommentare',
      'INTERACTIVE': 'Fragen',
      'NO_RESULT_FIRST': 'Es konnte kein Eintrag zu Ihrer Suche nach "',
      'NO_RESULT_SECOND':'" gefunden werden. Bitte versuchen Sie einen anderen Begriff.',
      'LOAD_COMMENTS': 'Kommentare werden geladen',
      'ENTER_COMMENT':'Geben Sie Ihren Kommentar ein',
      'NO_FORUM': 'Es gibt noch keine Kommentare',
      'FORUM_LOGIN': 'Bitte loggen Sie sich ein, um Kommentare zu senden',
      'FORUM_SEND': 'Senden',
      'VOTING_LOGIN': 'Bitte melden Sie sich an, um an der Abstimmung teilzunehmen',
      'VOTING_CONTROVERSIAL': 'Kontrovers-Frage',
      'VOTE_ALREADY_SENT' : 'Sie haben bereits abgestimmt',
      'VOTE_THANK': 'Vielen Dank für Ihre Abstimmung',
      'TOTAL_VOTES': 'Abstimmungen gesamt',
      'MY_QUESTION': 'Meine Frage',
      'ENTER_QUESTION': 'Geben Sie hier Ihre Frage ein',
      'QUESTION_SEND':' Senden',
      'SEND_AS':'Senden als ',
      'QUESTIONS_AUDIENCE':'Fragen vom Publikum',
      'DATA_UPDATE':'Aktualisieren',
      'PERSONAL_ACCOUNT': 'Mein Account',
      'ACCOUNT_EDIT': 'Profil bearbeiten',
      'ACCOUNT_EMAIL': 'Email',
      'ACCOUNT_PHONE' : 'Telefon',
      'ACCOUNT_ADDRESS': 'Adresse',
      'ACCOUNT_COUNTRY' : 'Land',
      'ACCOUNT_RESET' : 'Anwendung zurücksetzen',
      'RESET_HEADER':'Anwendung zurücksetzen',
      'RESET_TEXT':'Hiermit setzen Sie die Daten der Anwendung zurück. Sind Sie sich sicher?',
      'MY_PROFILE':'Mein Profil',
      'MY_CONGRESS':'Mein Kongress',
      'LOGOUT':'Ausloggen',
      'CONTACT_US': 'Kontaktieren Sie uns',
      'VISIT_BOOTH': 'Besuchen Sie unseren Stand',
      'ABOUT_US': 'Über uns',
      'RES_CENTRE': 'Ressourcenzentrum',
      'EXHIBITORS' : 'Ausstellungen',
      'NO_BIOGRAPHY' : 'Diese Person hat keine Biografie',
      'ADD_NOTE' : 'Notiz',
      'EDIT_NOTE' : 'Notiz',
      'DELETE_NOTE' : 'Notiz löschen',
      'SAVE_NOTE' : 'Notiz speichern',
      'CLOSE' : 'Schließen',
      'BOOTHS' : 'Stand',
      'NO_BOOTHS' : 'Dieser Sponsor hat keinen Stand',
      'WEBSITE' : 'Website',
      'SPECIAL_THANKS' : 'Besonderer Dank',
      'NO_NEWS' : 'Im Moment gibt es noch keine Nachrichten.',
      'SHOW_MAP' : 'Ort',
      'SESSION_NO_ACCESS' : 'Ihre Registrierung beinhaltet keinen Zugriff auf diesen Inhalt.',
      'EVALUATION_NOT_ACTIVE' : 'Die Bewertung ist für diese Sitzung nicht aktiv',
      'PRESENTATION_ABSTRACT' : 'Abstract',
      'INITIALIZING_DATA' : 'Daten initialisieren',
      'CHECKING_DATA' : 'Daten prüfen',
      'DELETE_ACCOUNT' : 'Mein Konto löschen',
      'PERS_ACCOUNT' : 'Persönliches Konto',
      'EXHIBITOR_WEBSITE' : 'Besuche unsere Webseite',
      'DOWNLOAD':'Lade herunter',
      'CALENDAR_SAVE':'Sitzung erfolgreich gespeichert',
      'CALENDAR_SAVE_ERROR':'Die Sitzung wurde nicht gespeichert. Fehler:',
      'SCANS_TITLE':'Gescannte Sitzungen',
      'PERSON_SESSIONS_TITLE': 'Beteiligt an diesen Sitzungen',
      'PERSON_PRESENTATIONS_TITLE':'Beteiligt an diesen Präsentationen',
      'TYPE_MESSAGE' : 'Schreiben Sie Ihre Nachricht',
      'SEARCH_PROGRAMME': 'Programm durchsuchen'
    },
    'en':{
      'FOUND_IN':'found in:',
      'FAVS' :'Favorites',
      'NOTES' :'Notes',
      'MAIL_EXPORT' :'Export to Mail',
      'LOGIN':'LOG IN',
      'IMPRINT': 'Imprint',
      'ABOUT':'About',
      'PRIVACY': 'Privacy',
      'SUPPORT': 'Support',
      'SEARCH' : 'Search',
      'ADD_FAVORITE' : 'Favorite',
      'RM_FAVORITE' : 'Saved',
      'SAVE_CALENDAR' : 'Save to calendar',
      'CHAIR_HEADER' : 'Chair',
      'SPEAKER_HEADER' : 'Speaker',
      'PRESENTATION_HEADER' : 'Presentations',
      'PERSON_SESSION_HEADER': 'Sessions of this person',
      'EVALUATE': 'Evaluate',
      'SESSION_HEADER': 'Session',
      'SESSION_HEADER_ALSO': 'Also presented in session',
      'NOW_HINT': 'No session running now or in the next 15 minutes',
      'SECTION_SESSION': 'Sessions',
      'SECTION_PRESENTATION': 'Presentations',
      'SECTION_PERSON': 'Persons',
      'SECTION_ABSTRACTS': 'Abstracts',
      'SECTION_INDUSTRY': 'Industry',
      'NEWS_HINT':'For now, there are no messages yet. Please come back later.',
      'COMMENTS': 'Comments',
      'INTERACTIVE': 'Questions',
      'NO_RESULT_FIRST': 'We\'re sorry, we didn\'t find a search result for "',
      'NO_RESULT_SECOND':'". Please try again.',
      'LOAD_COMMENTS': 'Loading comments',
      'ENTER_COMMENT':'Please enter your comment',
      'NO_FORUM': 'No comments activated',
      'FORUM_LOGIN': 'Please login to send comments',
      'FORUM_SEND': 'Send',
      'VOTING_LOGIN': 'Please login to participate in votings',
      'VOTING_CONTROVERSIAL': 'Controversial Question',
      'VOTE_ALREADY_SENT' : 'You´ve already voted',
      'VOTE_THANK': 'Thank you for your vote',
      'TOTAL_VOTES': 'Total votes',
      'MY_QUESTION': 'My question',
      'ENTER_QUESTION': 'Please enter your question',
      'QUESTION_SEND':' Send',
      'SEND_AS':'Send as ',
      'QUESTIONS_AUDIENCE':'Questions from the audience',
      'DATA_UPDATE':'Data Update',
      'PERSONAL_ACCOUNT': 'Mein Account',
      'ACCOUNT_EDIT': 'Edit Profile',
      'ACCOUNT_EMAIL': 'Email',
      'ACCOUNT_PHONE' : 'Phone',
      'ACCOUNT_ADDRESS': 'Address',
      'ACCOUNT_COUNTRY' : 'Country',
      'ACCOUNT_RESET' : 'Reset application data',
      'RESET_HEADER': 'Reset application data',
      'RESET_TEXT':'This will re-initialize the whole application. Are you sure?',
      'MY_PROFILE':'My Profile',
      'MY_CONGRESS':'My Congress',
      'LOGOUT':'Log Out',
      'VISIT_BOOTH': 'Visit our booth',
      'CONTACT_US': 'Contact us',
      'ABOUT_US': 'About us',
      'RES_CENTRE': 'Resource Center',
      'EXHIBITORS' : 'Exhibitors',
      'NO_BIOGRAPHY' : 'This person has no biography',
      'ADD_NOTE' : 'Add Note',
      'EDIT_NOTE' : 'Edit Note',
      'DELETE_NOTE' : 'Delete Note',
      'SAVE_NOTE' : 'Save Note',
      'CLOSE' : 'Close',
      'BOOTHS' : 'Visit our booths:',
      'NO_BOOTHS' : 'This Sponsor has no Booths',
      'WEBSITE' : 'Website',
      'SPECIAL_THANKS' : 'Special Thanks',
      'NO_NEWS' : 'There are no Messages right now.',
      'SHOW_MAP' : 'Show on map',
      'SESSION_NO_ACCESS' : 'Your registration does not include access to this content.',
      'EVALUATION_NOT_ACTIVE' : 'Evaluation is not active for this session',
      'PRESENTATION_ABSTRACT' : 'Abstract',
      'INITIALIZING_DATA' : 'Initializing data',
      'CHECKING_DATA' : 'Checking data',
      'DELETE_ACCOUNT' : 'Delete my account',
      'PERS_ACCOUNT' : 'Personal account',
      'EXHIBITOR_WEBSITE' : 'Visit our Website',
      'DOWNLOAD':'Downloading',
      'CALENDAR_SAVE':'Event saved succesfully',
      'CALENDAR_SAVE_ERROR':'Event could not be saved. Error: ',
      'SCANS_TITLE':'Scanned Sessions',
      'PERSON_SESSIONS_TITLE': 'Involved in these sessions',
      'PERSON_PRESENTATIONS_TITLE':'Involved in these presentations',
      'TYPE_MESSAGE' : 'Type your message',
      'SEARCH_PROGRAMME': 'Search the programme'
      
    },
    'fr':{
      'FOUND_IN':'found in:',
      'FAVS' :'Favoris',
      'NOTES' :'Remarques',
      'MAIL_EXPORT' :'Exporter vers e-mail',
      'LOGIN':'Connecter',
      'IMPRINT':'Impression',
      'ABOUT':'Au sujet de',
      'PRIVACY':'Protection des données',
      'SUPPORT':'Support',
      'SEARCH':'Recherche',
      'ADD_FAVORITE':'Ajouter aux favoris',
      'RM_FAVORITE':'Supprimer des favoris',
      'SAVE_CALENDAR' : 'enregistrer dans le calendrier',
      'CHAIR_HEADER':'Moderation',
      'SPEAKER_HEADER':'Speaker',
      'PRESENTATION_HEADER':'Presentations',
      'PERSON_SESSION_HEADER':'Sessions of this person',
      'EVALUATE':'Evaluez',
      'SESSION_HEADER':'Session ',
      'SESSION_HEADER_ALSO': 'également présenté en séance',
      'NOW_HINT':'Pas de session en cours ou pas avant 15 minutes',
      'SECTION_SESSION':'Sessions',
      'SECTION_PRESENTATION':'Presentations',
      'SECTION_PERSON':'Personnes',
      'SECTION_ABSTRACTS':'Abstracts',
      'SECTION_INDUSTRY':'Industrie',
      'NEWS_HINT':'Pas de message pour le moment, veuillez revenir plus tard.',
      'COMMENTS':'Commentaire',
      'INTERACTIVE':'Questions',
      'NO_RESULT_FIRST':'Désolé pas résultat de recherche',
      'NO_RESULT_SECOND':'Veuillez essayer à nouveau',
      'LOAD_COMMENTS':'En cours, veuillez patienter',
      'ENTER_COMMENT':'Veuillez introduire votre commentaire',
      'NO_FORUM':'Pas de commentaire activé',
      'FORUM_LOGIN':'Veuillez-vous connecter pour envoyer vos commentaires',
      'FORUM_SEND':'Envoyer',
      'VOTING_LOGIN':'Veuillez-vous connecter pour voter',
      'VOTING_CONTROVERSIAL':'Question controversée ',
      'VOTE_ALREADY_SENT ':'Vous avez déjà voté',
      'VOTE_THANK':'Merci pour votre vote',
      'TOTAL_VOTES':'Nombre total de votes',
      'MY_QUESTION':'Ma question',
      'ENTER_QUESTION':'Veuillez introduire votre question',
      'QUESTION_SEND':'Envoyer',
      'SEND_AS':'Envoyer comme',
      'QUESTIONS_AUDIENCE':"Question de l'assemblée",
      'DATA_UPDATE':'Mettre à jour',
      'PERSONAL_ACCOUNT': 'Mon compte',
      'ACCOUNT_EDIT': 'Modifier le compte',
      'ACCOUNT_EMAIL': 'E-mail',
      'ACCOUNT_PHONE' : 'téléphoner',
      'ACCOUNT_ADDRESS': 'adresse',
      'ACCOUNT_COUNTRY' : 'pays',
      'ACCOUNT_RESET' : 'Réinitialiser l\'application',
      'RESET_HEADER': 'Réinitialiser les données d\'application',
      'RESET_TEXT':'Cela réinitialisera toute l\'application. Es-tu sûr?',
      'MY_PROFILE':'Mon profil',
      'MY_CONGRESS':'Mon congrès',
      'LOGOUT':'Se déconnecter',
      'VISIT_BOOTH': 'Visitez notre stand',
      'CONTACT_US': 'Nous contacter',
      'ABOUT_US': 'À propos de nous',
      'RES_CENTRE': 'Centre de ressources',
      'EXHIBITORS' : 'Expositions',
      'NO_BIOGRAPHY' : 'Cette personne n\'a pas de biographie',
      'ADD_NOTE' : 'Ajouter une note',
      'EDIT_NOTE' : 'Modifier la note',
      'DELETE_NOTE' : 'Supprimer la note',
      'SAVE_NOTE' : 'enregistrer la Note',
      'CLOSE' : 'Fermer',
      'BOOTHS' : 'Stand',
      'NO_BOOTHS' : 'Ce commanditaire n\'a pas de stand',
      'WEBSITE' : 'Page web',
      'SPECIAL_THANKS' : 'Remerciement Spécial',
      'NO_NEWS' : 'Il n\'y a aucun message pour le moment.',
      'SHOW_MAP' : 'Emplacement',
      'SESSION_NO_ACCESS' : ' Votre inscription n\'inclut pas l\'accès à ce contenu.',
      'EVALUATION_NOT_ACTIVE' : 'L\'évaluation n\'est pas active pour cette session',
      'PRESENTATION_ABSTRACT' : 'Abstract',
      'INITIALIZING_DATA' : 'Initialisation des données',
      'CHECKING_DATA' : 'Vérification des données',
      'DELETE_ACCOUNT' : 'Supprimer mon compte',
      'PERS_ACCOUNT' : 'Compte personnel',
      'EXHIBITOR_WEBSITE' : 'Visitez notre site Internet',
      'DOWNLOAD':'Download',
      'CALENDAR_SAVE':'Session enregistrée avec succès',
      'CALENDAR_SAVE_ERROR':'La session n\'a pas été enregistrée. Erreur:',
      'SCANS_TITLE':'Sessions Scannées',
      'PERSON_SESSIONS_TITLE': 'Impliqué dans ces séances',
      'PERSON_PRESENTATIONS_TITLE':'Impliqué dans ces présentations',
      'TYPE_MESSAGE' : 'écrivez ici',
      'SEARCH_PROGRAMME': 'Rechercher le programme'
    }
  }
}

const actions = {
    changeLanguage({state}, lang){
      state.currentLanguage = lang;
    }

}
const mutations = {
}
const getters = {
}

export const wordings = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
