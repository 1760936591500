import {createRouter, createWebHashHistory} from '@ionic/vue-router';
import {store} from "@/store/index";

const config = require('@/config')
const replaceRoutes = {
    '#/details/sessions/286,287,285,293,273,306,307,302,298,299,300,301,303,304,305,283,272,294,267,271,274,270,278,275,276,277,279,280,281,282,284,288,289,290,291,292,295,296,297': '/app/collections/da7ad0c0-3ed1-4500-1350-010000000266',
    '#/details/sessions/258,259,234,260,231': '/app/collections/da7ad0c0-3ed1-4500-1350-010000000253'
}

const routes = [

    {
        path: '/',
        component: () => import('@/components/Menu.vue'),
        redirect: 'app',
    },
    {
        path: '/app',
        component: () => import('@/components/Menu.vue'),
        redirect: () => {
            if (config.is_multi_conf_app) {
                let defaultDB = localStorage.getItem('defaultDB')
                console.log('DEFAULT', defaultDB, config.conference)
                if (!defaultDB || defaultDB == config.conference) {
                    return '/app/home'
                } else {
                    return '/app/dashboard'
                }
            } else {
                return '/app/dashboard'
            }
        },

        children: [

            {
                path: '/',
                name: 'SessionList',
                component: () => import( '../views/Session/SessionList.vue')
            },
            {
                path: 'home',
                name: 'Home',
                component: () => import( '../views/Dashboard/Home.vue')
            },

            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import( '../views/Dashboard/Dashboard.vue')
            }, {
                path: 'dashboard/:id',
                name: 'DashboardSub',
                component: () => import( '../views/Dashboard/DashboardSub.vue')
            },
            {
                path: 'sessions',
                name: 'SessionList',
                component: () => import( '../views/Session/SessionList.vue')
            },
            {
                path: 'sessionlist',
                name: 'SessionList',
                component: () => import( '../views/Session/SessionList.vue')
            },
            {
                path: 'locationsfiltered/:room/:start/:end',
                name: 'SessionListFiltered',
                component: () => import( '../views/Session/SessionListFiltered.vue')
            },
            {
                path: 'locations/:id',
                name: 'SessionByLocation',
                component: () => import( '../views/Session/SessionsByLocation.vue')
            },
            {
                path: 'exhibitorlocations/:id',
                name: 'ExhibitorsByLocation',
                component: () => import( '../views/Industry/ExhibitorsByLocation.vue')
            },
            {
                path: 'sessionlist/:day',
                name: 'SessionListDay',
                component: () => import( '../views/Session/SessionList.vue')
            },
            {
                path: 'collections/:collection_id',
                name: 'SessionCollectionList',
                component: () => import( '../views/Session/SessionCollectionDetail.vue')
            },
            {
                path: 'sessions/now',
                name: 'Sessions Now',
                component: () => import('../views/Session/SessionListNow.vue')
            },
            {
                path: 'sessions/date/:day',
                name: 'SessionList',
                component: () => import( '../views/Session/SessionList.vue')
            },

            {
                path: 'sessions/:id',
                name: 'Session Detail',
                component: () => import('../views/Session/SessionDetail.vue')
            },
            {
                path: 'liveroom/:id',
                name: 'Live Room',
                component: () => import('../views/Session/LiveRoom.vue')
            },
            {
                path: 'liverooms',
                name: 'Live Room Overview',
                component: () => import('../views/Session/LiveRoomOverview.vue')
            },

            {
                path: 'gridList',
                name: 'GridList',
                component: () => import( '../views/Session/SessionGrid.vue')
            },
            {
                path: 'persons',
                name: 'Persons',
                component: () => import('../views/Person/Persons.vue')
            },
            {
                path: 'persons/:id',
                name: 'Person Detail',
                component: () => import('../views/Person/PersonDetail.vue')
            }, {
                path: 'sponsors',
                name: 'Sponsors',
                component: () => import('../views/Industry/Sponsors.vue')
            }, {
                path: 'sponsors/:level',
                name: 'Sponsors By level',
                component: () => import('../views/Industry/SponsorsByLevel.vue')
            },
            {
                path: 'exhibitions',
                name: 'Exhibitors',
                component: () => import('../views/Industry/Exhibitors.vue')
            },
            {
                path: 'exhibitionsbybooth',
                name: 'Exhibitors ByBooth',
                component: () => import('../views/Industry/ExhibitorsByBooth.vue')
            },
            {
                path: 'exhibitors/:id',
                name: 'Exhibitors Detail',
                component: () => import( '../views/Industry/ExhibitorDetail.vue')
            },
            {
                path: 'generalinfo',
                name: 'Subpages',
                component: () => import( '../views/Subpage/Subpages.vue')
            },
            {
                path: 'generalinfo/:id',
                name: 'Subpage Detail',
                component: () => import('../views/Subpage/SubpageDetail.vue')
            },
            {
                path: 'sessiontypes',
                name: 'Sessiontypes',
                component: () => import( '../views/SessionType/SessionTypes.vue')
            },
            {
                path: 'sessiontypes/:id',
                name: 'Sessiontype Detail',
                component: () => import( '../views/SessionType/SessionTypeDetail.vue')
            },
            {
                path: 'tags',
                name: 'Tag List',
                component: () => import( '../views/SessionType/SessionTags.vue')
            },
            {
                path: 'tags/:id',
                name: 'Tag Detail',
                component: () => import( '../views/SessionType/SessionTagDetail.vue')
            },
            {
                path: 'presentations/:id',
                name: 'Presentation Detail',
                component: () => import( '../views/Presentation/PresentationDetail.vue')
            },
            {
                path: 'posterList',
                name: 'Poster List',
                component: () => import( '../views/Presentation/PosterList.vue')
            },

            {
                path: 'abstracts',
                name: 'Abstracts',
                component: () => import( '../views/Abstracts/Abstracts.vue')
            },
            {
                path: 'abstracts/:id',
                name: 'Abstract Detail',
                component: () => import( '../views/Abstracts/AbstractDetail.vue')
            },
            {
                path: 'search',
                name: 'Search',
                component: () => import( '../views/Search/Search.vue')
            },
            {
                path: 'search/:term',
                name: 'Search with term',
                component: () => import( '../views/Search/Search.vue')
            },
            {
                path: 'maps',
                name: 'Maps',
                component: () => import( '../views/Maps/Maps.vue')
            },
            {
                path: 'maps/:id',
                name: 'Map Detail',
                component: () => import( '../views/Maps/MapDetail.vue')
            },
            {
                path: 'locationmap/:location',
                name: 'Map Detail with marker',
                component: () => import( '../views/Maps/MapDetail.vue')
            },
            {
                path: 'chat',
                name: 'Chat',
                component: () => import( '../components/chat/ChatUsers.vue')
            }, {
                path: 'mychats',
                name: 'My Chats',
                component: () => import( '../components/chat/ChatList.vue')
            },
            {
                path: 'chats/:id',
                name: 'Chat Detail',
                component: () => import( '../components/chat/ChatDetail.vue')
            },
            {
                path: 'posts',
                name: 'Newsboard',
                component: () => import( '../views/News/Newsboard.vue')
            },
            {
                path: 'lobbyroom/:id',
                name: 'Lobby',
                component: () => import( '../views/Lobby/Lobbyroom.vue')
            },
            {
                path: 'meetings',
                name: 'Meetings',
                component: () => import( '../views/Meetings/MeetingsList.vue')
            },
            {
                path: 'consultantmeetings',
                name: 'Consultant Meetings',
                component: () => import( '../views/Meetings/ConsultantMeetings.vue')
            },
            {
                path: 'meetingsmanager',
                name: 'Meetings Manager',
                component: () => import( '../views/Meetings/MeetingsManager.vue')
            },
            {
                path: 'videoroom/:id',
                name: 'Videoroom',
                component: () => import( '../views/Meetings/Videoroom.vue')
            },
            {
                path: 'userinfo',
                name: 'User Info',
                component: () => import( '../views/User/UserInfo.vue')
            },
            {
                path: 'polls/:id/:ref_type/:ref_id',
                name: 'Poll Detail',
                component: () => import('../views/Polls/PollDetail.vue')
            },
            {
                path: 'favorites',
                name: 'My Congress',
                component: () => import('../views/Favorites/FavoriteList')
            },
            {
                path: 'scanlist',
                name: 'ScanList',
                component: () => import('../views/ScanList')
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*", component: () => import('@/components/Menu.vue'),
        redirect: '/app',
    },


]


const router = createRouter({
    history: createWebHashHistory(),
    routes
});


router.beforeEach(async (to, from, next) => {
    //check for token in path
    let token = location.href.match(/token=([^&]*)/) || false;

    console.log('ROUTE', location.hash)

    console.log(token[1])
    if (token[1]) {
        //set the token in localstrorage
        localStorage.setItem("x-api-key", token[1]);
        let user = await store.dispatch("auth/GET_USERDATA");

        if (user.data) {
            await store.commit("auth/SET_USER", user.data);
            localStorage.setItem('user', JSON.stringify(user.data));
        }
        //splice the path
        window.history.pushState(
            {},
            document.title,
            '/'
        )
        if (localStorage.getItem('lastPageBeforeLogin')) {
            let redirect = localStorage.getItem('lastPageBeforeLogin').split('#')[1]
            router.push(redirect);
        } else {
            router.push({name: 'Dashboard'});
        }
        next();

    } else {
        localStorage.setItem('lastViewedRoute', from.name)
        next();
    }
});

export default router
