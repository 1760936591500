import axios from 'axios';

import {getEndpoints} from '../endpoints'

const config = require('@/config');

//const initdata = require('@/assets/1302_appdata.json')

import {alertController, toastController} from '@ionic/vue';
import PouchDB from 'pouchdb';


const namespaced = true

const version = '--v1--';
const state = {
    baseUrl: config.baseUrl,
    baseUrlDownload: config.baseUrlDownload,
    baseUrlCDN: config.baseUrlCDN,
    cdnInitStamp: config.cdnInitStamp,
    defaultDB: localStorage.getItem('defaultDB') || config.conference,
    short_name: config.short_name,
    api_version: config.api_version,
    endpoints: getEndpoints(),
    updateFinished: false,
    syncActive: false,
    downloadSize: 0,
    downloadLabel: '',
    currentProgress: 0,
    downloadLabelText: 'Downloading'

}

const actions = {

    async start({state, dispatch, commit}) {
        state.updateFinished = false;
        state.syncActive = true;
        state.downloadSize = 0;
        state.downloadLabel = '';
        state.currentProgress = 0;
        console.log('DEFAULT', state.defaultDB)
        if(state.defaultDB == 'conf2go'){
            state.updateFinished = true;
            state.syncActive = false;
            return true
        }else{
            let dbfilled = await dispatch('database/isFilled', {}, {root: true});
            console.log(dbfilled,commit)
            return new Promise((resolve, reject) => {
                dispatch('getServerTime').then(async servertime => {
                    let activeEndpoints = state.endpoints;
                    if (dbfilled) {
                        console.log('dbfilled, check udpates');
                        let endpointsToSync = await dispatch('checkAllEndpoints', {endpoints: activeEndpoints});
                        console.log(endpointsToSync)

                        let sizebytes = endpointsToSync.size / 1000;
                        let bytename = ' KB';
                        if (sizebytes > 1000) {
                            sizebytes /= 1000;
                            bytename = ' MB';
                        }
                        sizebytes = Math.ceil(sizebytes * 100) / 100;
                        state.downloadSize = sizebytes;
                        state.downloadLabel = bytename;
                        state.downloadLabelText = 'Downloading'

                        //if (!force) {
                        //downloadDialog.toggle(endpointsToSync.size).then(() => {
                        //observable.onNext('showModal');
                        //syncActive = true;
                        let updated = await dispatch('updateAllEndpoints', {
                            endpoints: endpointsToSync.endpoints,
                            servertime: servertime
                        });
                        //await commit('splashscreen/setShowSplash', true, {root: true});
                        if (updated) {
                            state.updateFinished = true;
                            state.syncActive = false;
                            resolve(updated);

                        } else {
                            state.updateFinished = true;
                            state.syncActive = false;
                            resolve(updated);
                        }
                    } else {
                        console.log('db not filled, load via cdn');
                        //TODO:do CDN update
                        //observable.onNext('showModal');
                        //downloadDialog.hide();
                        let updated = await dispatch('loadFromCDN', {});
                        if (updated) {
                            /*state.updateFinished = true;
                            state.syncActive = false;
                            resolve(updated);*/
                            let endpointsToSync = await dispatch('checkAllEndpoints', {endpoints: activeEndpoints});
                            console.log(endpointsToSync)

                            let sizebytes = endpointsToSync.size / 1000;
                            let bytename = ' KB';
                            if (sizebytes > 1000) {
                                sizebytes /= 1000;
                                bytename = ' MB';
                            }
                            sizebytes = Math.ceil(sizebytes * 100) / 100;
                            state.downloadSize = sizebytes;
                            state.downloadLabel = bytename;
                            //if (!force) {
                            //downloadDialog.toggle(endpointsToSync.size).then(() => {
                            //observable.onNext('showModal');
                            //syncActive = true;
                            let updated = await dispatch('updateAllEndpoints', {
                                endpoints: endpointsToSync.endpoints,
                                servertime: servertime
                            });
                            if (updated) {
                                state.updateFinished = true;
                                state.syncActive = false;
                                resolve(updated);

                            } else {
                                state.updateFinished = true;
                                state.syncActive = false;
                                resolve(updated);
                            }

                        } else {
                            state.updateFinished = true;
                            state.syncActive = false;
                            resolve(updated);
                        }


                    }
                }).catch((err) => {
                    state.updateFinished = true;
                    state.syncActive = false;
                    console.log(err)
                    reject(err);
                });
            })
        }


    },


    async checkAllEndpoints({state,rootState}, payload) {
        let endpoints = payload.endpoints;
        let initStamp = '2017-01-01T00:00:00';
        //version=1 altes system; version= 2 neues system
        let postData = {
            id: rootState.currentConference.currentConference,
            name: rootState.currentConference.short_name,
            version: state.api_version,
            endpoints: {}
        };
        for (let i = 0; i < endpoints.length; i++) {
            let endpoint = endpoints[i];
            let timestamp = localStorage.getItem(state.defaultDB + version + endpoint) || initStamp;
            timestamp = timestamp.split('"').join('');

            postData.endpoints[endpoint] = timestamp;
        }
        try {
            let res = (await axios.post(state.baseUrl, postData)).data;
            return res;
        } catch (err) {
            return null
        }
    },
    updateAllEndpoints({state,rootState, dispatch}, payload) {
        let endpoints = payload.endpoints;
        let servertime = payload.servertime;
        return new Promise((resolve) => {
            axios.post(state.baseUrlDownload, {endpoints: endpoints}).then(async data => {
                let res = data.data;
                console.log(res)
                let count = 0;
                let all = Object.keys(res).length;
                for (let key in res) {
                    let x = await dispatch('saveUpdate', {data: res[key], table: key, _servertime: servertime});
                    console.log(key + 'saved', x);
                    count++
                    state.currentProgress = ((count / all));
                    if(x){
                        localStorage.setItem(rootState.currentConference.currentConference + version + key, servertime);
                    }
                }
                await resolve(true);
            }).catch((err) => {
                console.log(err)
            });
        });  
    },

    async loadFromCDN({state,rootState, dispatch}) {
        console.log(state, dispatch)
        
        let _data;
        if (!config.is_app) {
            let url = state.baseUrlCDN + rootState.currentConference.currentConference + '_appdata.json';
            let response = await fetch(url)
            _data = await response.json()
        }

       if (config.is_app) {
            _data = require('@/assets/' + state.defaultDB + '_appdata.json')
        }

        //let _data = initdata;//data.data
        const size = Buffer.byteLength(JSON.stringify(_data))
        let sizebytes = size / 1000;
        let bytename = ' KB';
        if (sizebytes > 1000) {
            sizebytes /= 1000;
            bytename = ' MB';
        }
        sizebytes = Math.ceil(sizebytes * 100) / 100;
        state.downloadSize = sizebytes;
        state.downloadLabel = bytename;
        state.downloadLabelText = 'Installing'

        let res = _data;
        let count = 0;
        let all = Object.keys(res).length;
        for (let key in res) {
            //let x =
            await dispatch('saveUpdate', {
                firstLoad: true,
                data: res[key],
                table: key,
                _servertime: state.cdnInitStamp
            });
            //console.log(key + 'saved', x);
            count++
            state.currentProgress = ((count / all));
            localStorage.setItem(rootState.currentConference.currentConference + version + key, state.cdnInitStamp);
        }
        return true;
        //});

    }
    ,
    getServerTime() {
        return new Promise((resolve, reject) => {
            let time_url = 'https://mantel-api.mobile.documedias.systems/v2/timestamp';
            axios.get(time_url).then(time => {
                resolve(time.data);
            }).catch((err) => {
                reject(err);
            })
        });
    }
    ,
    async saveUpdate({dispatch}, payload) {
        let data = payload.data;
        let tableName = payload.table;
        let _servertime = payload._servertime;
        let firstLoad = payload.firstLoad;

        if (firstLoad) {
            return new Promise((resolve, reject) => {
                dispatch('database/initDatabaseItems', {items: data, table: tableName}, {root: true}).then(res => {
                    //console.log('result from init', res)
                    if (res) {
                        localStorage.setItem(state.defaultDB + version + tableName, _servertime);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                dispatch('database/upsertItems', {items: data, table: tableName}, {root: true}).then(res => {
                    console.log('check', res)
                    if (res) {
                        localStorage.setItem(state.defaultDB + version + tableName, _servertime);
                    }
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });
        }

    }
    ,


    async resetData({dispatch, state,rootState}) {
        let wordings = rootState.wordings.wordings;
        let current = rootState.wordings.currentLanguage;
        let header = wordings[current].RESET_HEADER;
        let text = wordings[current].RESET_TEXT;

        const alert = await alertController
            .create({
                header: header,
                message: text,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        id: 'cancel-button',
                        handler: () => {
                            return false
                        },
                    },
                    {
                        text: 'Okay',
                        id: 'confirm-button',
                        handler: async () => {
                            console.log('POUCH', PouchDB, state.defaultDB)
                            let res = await dispatch('database/destroyDB', {}, {root: true});
                            for (let key in localStorage) {
                                if (key.indexOf(state.defaultDB + '--v1--') === 0) {
                                    localStorage.removeItem(key);
                                }
                            }
                            if (res) {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500)
                            }
                        },
                    },
                ],
            });
        alert.present();
    }
    ,
    async showSyncToast() {
        const toast = await toastController
            .create({
                message: 'Application is now up to date',
                duration: 2000,
                position: 'bottom',
                animated: 'true',
                color: 'primary',
                cssClass: 'update-toast',
            })
        return toast.present();
    }
    ,

}
const mutations = {}
const getters = {}

export const sync = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
