import {isPlatform} from "@ionic/vue";

const config = require('@/config');
import router from '@/router'
import axios from "axios";
import {modalController} from '@ionic/vue';
import ConfLoadModal from "@/components/ConfLoadModal";


const namespaced = true

const state = {
    currentConference: localStorage.getItem('defaultDB') || config.conference,
    fullName: localStorage.getItem('confFullName') || config.full_name,
    short_name: localStorage.getItem('confShortName') || config.short_name,
    apiVersion: localStorage.getItem('confApiVersion') || config.api_version,
    menuheader: localStorage.getItem('confMenuHeader'),
    altFont: localStorage.getItem('confAltFont'),
    primaryColor: localStorage.getItem('confPrimaryColor'),
    icon: localStorage.getItem('confIcon'),
    loadingModal: null,
    chatUrl: localStorage.getItem('confChatUrl') || config.chat_url,

}

const actions = {
    async changeConference({commit, dispatch}, conf) {
        console.log(conf)
        commit('setCurrentConference', conf.id);
        commit('setShortName', conf.short_name.toLowerCase());
        commit('setChatUrl', 'https://' + conf.short_name.toLowerCase() + '.chat.documedias.systems/api/');
        commit('setApiVersion', conf.api_version);

        dispatch('showLoadingModal', conf);
        await dispatch('database/changeDB', conf.id + '', {root: true});
        await dispatch('registerPush', {});
        await dispatch('sync/start', {}, {root: true});
        dispatch('hideLoadingModal');
        commit('setFullName', conf.full_name);
        commit('setAltFont', conf.alt_font);
        commit('setPrimaryColor', conf.primary_color);
        commit('setIcon', 'https://fileserver.documedias.systems/file/' + conf.icon);
        commit('setMenuHeader', conf.head);
        await dispatch('wordings/changeLanguage',conf.language || 'en', {root: true})
        await router.push(conf.start_page || '/app/dashboard')
        await commit('splashscreen/setShowSplash', true, {root: true});
    },
    async backToSociety({commit, dispatch}) {
        commit('setCurrentConference', config.conference);
        commit('setFullName', config.full_name);
        commit('setShortName', config.short_name.toLowerCase());
        commit('setApiVersion', config.api_version);
        commit('setAltFont', '#FFF');
        commit('setPrimaryColor', '#00417D');
        localStorage.removeItem('confMenuHeader')
        localStorage.removeItem('confChatUrl')
        await dispatch('database/changeDB', config.conference + '', {root: true});
        //await dispatch('sync/start', {}, {root: true});
        router.push('/app/home')
    },

    async showLoadingModal({state}, conf) {
        console.log(state)
        state.loadingModal = await modalController.create({
            component: ConfLoadModal,
            cssClass: 'my-custom-class',
            componentProps: {
                title: conf.full_name,
                color: conf.primary_color,
                font: conf.alt_color,
                icon: conf.icon,
                head: conf.head,
                description: conf.description

            },
        });
        return state.loadingModal.present();
    },
    hideLoadingModal({state}) {
        if (state.loadingModal) {
            state.loadingModal.dismiss()
            state.loadingModal = null
        }
    },
    async registerPush({state, rootState}) {
        let params = {}
        let platform = 'web';

        if (isPlatform('ios')) {
            platform = 'ios'
        }

        if (isPlatform('android')) {
            platform = 'android'
        }

        let token = localStorage.getItem('pushtoken');
        params = {'type': platform, 'token': token}
        console.log(params)

        if (rootState.auth.user) {
            console.log("nay")
            if (rootState.auth.user.id) {
                console.log("if " + rootState.auth.user + " " + rootState.auth.user.id)
                axios.post('https://push.mobile.documedias.systems/subscribe/', {
                    conference: state.currentConference,
                    user: rootState.auth.user.id,
                    platform: params.type,
                    token: params.token,
                    application: config.full_name,
                });
            }
        } else {
            axios.post('https://push.mobile.documedias.systems/subscribe/', {
                conference: state.currentConference,
                platform: params.type,
                token: params.token,
                application: config.full_name,
            });
        }
    }

}
const mutations = {
    setCurrentConference: (state, data) => {
        localStorage.setItem('defaultDB', data)
        //Vue.set(state, 'currentConference', data)
        state.currentConference = data
    },
    setFullName: (state, data) => {
        localStorage.setItem('confFullName', data)
        //Vue.set(state, 'fullName', data)
        state.fullName = data
    },
    setShortName: (state, data) => {
        localStorage.setItem('confShortName', data)
        state.short_name = data
    },
    setApiVersion: (state, data) => {
        localStorage.setItem('confApiVersion', data)
        state.apiVersion = data
    },
    setMenuHeader: (state, data) => {
        localStorage.setItem('confMenuHeader', data)
        state.menuheader = data
    },
    setAltFont: (state, data) => {
        localStorage.setItem('confAltFont', data)
        state.altFont = data
    },
    setPrimaryColor: (state, data) => {
        localStorage.setItem('confPrimaryColor', data)
        state.primaryColor = data
    },
    setChatUrl: (state, data) => {
        localStorage.setItem('confChatUrl', data)
        state.chatUrl = data
    },
    setIcon: (state, data) => {
        localStorage.setItem('confIcon', data)
        state.icon = data
    },
}
const getters = {
    getCurrentConference: (state) => {
        return state.currentConference;
    },
    getFullName: (state) => {
        return state.fullName;
    },
    getShortName: (state) => {
        return state.shortName;
    },
    getApiVersion: (state) => {
        return state.apiVersion;
    },
    isSociety: (state) => {
        return state.currentConference == config.conference
    },
    isConf2GoActive: (state) => {
        return state.currentConference == 'conf2go'
    }
}

export const currentConference = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
