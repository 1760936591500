const namespaced = true

const state = {
}

const actions = {
    async getAllSettings({dispatch}){
        return await dispatch('database/getItems', {table: 'settings'}, {root:true})
    },
    async getSetting({dispatch},id){
        try{
            let settings = await dispatch('database/getItems', {table: 'configurations'}, {root:true});
            let setting = settings.find(s=>s.key==id).value;
            return setting;
            //return (await dispatch('database/getItem', {table: 'settings', id:id}, {root:true})).value;
        }catch(err){
            console.log(err);
            return null
        }


  },
    async getConfig({dispatch},id){
        try{
            let settings = await dispatch('database/getItems', {table: 'configurations'}, {root:true});
            let setting = settings.find(s=>s.key==id).value;
            return setting;
            //return (await dispatch('database/getItem', {table: 'settings', id:id}, {root:true})).value;
        }catch(err){
            console.log(err);
            return null
        }


    }
}
const mutations = {
}
const getters = {
}

export const settings ={
  namespaced,
  state,
  getters,
  mutations,
  actions
}
